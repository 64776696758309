import { useState } from "react";

const BannerNav = () => {
    const [navFilled, setNavFilled] = useState(false);
    const updateNavFilled = () => {
        if (window.scrollY >= window.innerHeight - 200) {
            setNavFilled(true);
        }
        else {
            setNavFilled(false);
        }
    };
    window.addEventListener('scroll', updateNavFilled);


    return(
    <nav className="navbar navbar-expand-sm navbar-dark fixed-top"
    style = {{backgroundColor: navFilled ? "#021027" : ""}}
    
    >
        <div className="container-fluid">
            <a className="navbar-brand" href="#home">Home</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link text-white" href="#about">About</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" href="#academic">Academic</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" href="#projects">Projects</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1izIT8sDtXIhSUQiubpJVJOdexYlkV428Z9wCjT3PuoA/edit?usp=sharing">Résumé</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>)
}

export default BannerNav