import BodySection from "./BodySection";
import CardSet from "./Cardset";
import URLButton from "./URLButton";

function About() {
    return (
        <div className="content-body bg-white">

            <BodySection gray={true} round={true} img="fractal.png" title="About Me" idname ="about"
                btnurl="https://docs.google.com/document/d/1izIT8sDtXIhSUQiubpJVJOdexYlkV428Z9wCjT3PuoA/edit?usp=sharing" btntxt="Resume"
                linkedPhrase="Poly Prep Country Day School" linkedtxturl="https://www.polyprep.org/?s=Marisa+Triola&search=Search"
                txt = "I'm Marisa Triola, an freshman computer science major at NYU Tandon School of Engineering. I am a graduate of Poly Prep Country Day School in Brooklyn, NY. My interests include web development and choral singing." />
            <h2 className="pt-5 pb-0" id="academic">Academic Projects</h2>
            <BodySection reverse={true} img="plot.png" title="Computational Multiomics at NYU Langone" idname = "ruggleslab"
                btnurl="https://www.ruggleslab.org/" btntxt="Ruggles Lab"
                txt="At Ruggles Lab at NYU, I worked Tania González-Robles in creating an interactive dashboard application which allows users to query and visualize proteomic and transcriptomic data from the CPTAC, CCLE, and PRIDE datasets." />

            <BodySection img="nhd.jpg" title="National History Day" idname = "nhd"
                btnurl="https://nhd.marisa.app" btntxt="View Projects"
                txt="From seventh grade through my senior year in high school, I have participated in the National History Day competition. For five of those years, I entered into the website division of the competition, combining multimedia primary sources with my own analysis to argue my thesis statment in the form of a website." />
            <CardSet />
        </div>

    );
}

export default About;